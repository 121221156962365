.maincontainer{
    margin-top: 50px;
    padding: 90px;
    display: flex;
    flex-wrap: wrap;
}

.text__container{
    width: 60%;
    font-size: 22px;
    display: flex;
    flex-direction: column;
}

.avatar__container{
    position: relative;
    width: 300px;
    height: 300px;
    margin-right: 100px;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.image{
    position: relative;
    height: 300px;
    display: flex;
    border-bottom: 2px solid blue;
}

.triangle{
    position: absolute;
    width: 0;
    height: 0;
    border-right:170px solid transparent;
    border-bottom: 300px solid blue;
    border-left: 170px solid transparent;
}

.triangle__container{
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    width: 80%;
    margin-left: 10%;
    height: 50px;
    padding: 2px;
}

.triangle__border__bottom{
    width: 0;
    height: 0;
    border-right:10px solid transparent;
    border-bottom: 20px solid blue;
    border-left: 10px solid transparent;   
    margin-top: 5px;
    margin-right: 3px;
}

.triangle__border__top{
    width: 0;
    height: 0;
    border-top: 20px solid rgb(3, 113, 75);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent; 
    margin-right: 3px;
}

.triangle__container__top,
.triangle__container__bottom{
    display: flex;
}

.triangle__container__bottom{
    position: absolute;
    left: -27px;
}


.title {
    font-family: Bodoni MT ;
    font-size: 46px;
    font-weight: bold;
    color: var(--blue);
    text-align: center;
}

.text {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #555;
}


@media all and (max-width: 750px){
.avatar__container{
    margin-left: auto;
    margin-right: auto;
    /* background-color: antiquewhite; */
}

.maincontainer{
    padding: 8px;
}
.title{
    font-size: 35px;
}
.text__container{
    width: 100%;
}
}