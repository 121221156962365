.maincontainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
    background-color: var(--pink);
    position: relative;
}

.backgrond{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(253, 226, 255, 0.447);
}