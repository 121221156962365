
@import url(https://fonts.googleapis.com/css?family=Saira+Stencil+One);
@import url('./../..//const/colors.css');
.maincontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 800px;
    font-family: "Saira Stencil One", cursive!important;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;

    color: var(--pink);

    background-color: rgba(3, 71, 106, 0.475);
}


.background{
  position: absolute;
  
  background-image: url('./../../imagenes/_e34833ab-001e-479e-b77a-5027bb0fc799.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 100%;
  height: 800px;
  z-index: -1;
}

.separate{
  margin: 0 4px !important;
}
.title span, 
.subtitle span {
  display: inline-block;
  margin: 0 0.3px;
  transition: transform 0.2s;
  font-size: 58px;
  
}
.subtitle span {
  font-size: 40px;
}
.title span:hover, .subtitle span:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

.title,
.subtitle {
  display: flex ;
  flex-direction: row ;
  flex-wrap: wrap;
}

.btn_cont{
  margin-top: 50px;
  width: auto;
  font-size: 36px;
  padding: 15px;
  border: none;
  border-radius: 50px ;
  background-color: var(--pink);

}

.btn_cont:hover{
  opacity: 0.8;
}