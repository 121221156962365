.Technologies__container{
    width: 100%;
    /* height: 400px; */
    background-image: url('./../../imagenes/abstract-flat-circles-background-vector.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border: 3px solid var(--pink);
}