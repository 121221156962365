.image__container{
    width:100%;
    margin-bottom: 30px;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

}

.image{
    width: 50%;
    margin-left:20px;
    min-height: 400px;
    min-width: 400px;
    transition: 2s;
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    object-fit: cover;
}

.main__container{
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background-color: rgba(254, 252, 252, 0.581);
    justify-content:center;
    width: 100%;
    border: 2px solid var(--green);
    align-content: center;
    margin-bottom: 30px;
    padding: 20px;
    z-index: 1;
}


.right__container{
    display: flex;
    flex-direction: column;
    width: 40%;
    min-width: 450px;
    justify-content: center;
}
.url__container{
    width: 100%;
    display: flex;
    justify-content:space-around;
    align-items: start;
    margin-top: 10px;

}

.btn__url{
    background-color: var(--pink);
    border: 2px solid var(--green);
    color:var(--green);
    width: 100px;
    height: 30px;
    transition: 1;
}

.btn__container{
    position: relative;
    margin-top: 10px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    
}

.btn__container:hover .btn__span{
    transform: translateX(0px);
}

.btn__container:hover .btn__url{
    color: white;
    opacity: 1;
}

.btn__container:hover .btn__span__botom{
    transform: translateX(-0px);
}
.btn__span,
.btn__span__botom{
    position: absolute;
    background-color: var(--blue);
    border: 3px solid rgba(70, 230, 255, 0.51);
    width: 100px;
    height: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
    overflow: hidden;
    transition: 0.75s;
    opacity: 0.3;

}

.btn__span{
    top: 0;
    border-bottom: none;
    transform: translateX(-100px);
}

.btn__span__botom{
    bottom: 0;
    border-top: none;
    transform: translateX(100px);
}
.image:hover{
    cursor: pointer;
    filter: grayscale(100%);
    transition: 1s;
    opacity: 0.8;
}
.title {
    font-family: Bodoni MT ;
    font-size: 46px;
    font-weight: bold;
    color: var(--blue);
    text-align: center;
  }
  
  .text {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #555;
  }


@media all and (max-width: 750px){
    .image__conteiner{
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
    }
    .image{
        height: 300px;
        margin-left: 0;
        margin-bottom: 20px;
    }
    .main__container{
        padding: 0;
        width: 100%;
    }

    .right__container{
        min-width: 95% !important;
    }
    .url__container{
        align-items: center !important;
        justify-content: center;
        width: 80%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .btn__url, .btn__container{
        margin-left: auto;
    }
    .title{
        font-size: 25px;
    }
    
}