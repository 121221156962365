.maincontainer{
    width: 100%;
    height: 240px;
    padding-left: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    padding-right: 50px;
}

.Packman_conteiner{
    height: 200px;
    width: 200px;
   position: relative;
   display: flex;
   margin-right: -100px;
}
.Packman1,
.Packman2{
    position: relative;
    height: 200px;
    width: 200px;
    z-index: 1;
}

.packman2_conteiner{
    position: absolute;
    animation: pacmanEating 0.3s infinite;
    animation-timing-function: linear;
    width: 0;
    overflow: hidden;
}
@keyframes  pacmanEating  {
    0%{
        overflow: hidden;
    }
    100% {
        overflow: visible;
    }
  }
  

  .food_container,
  .sub_food_container{
        height: 100px;
       position: relative;
       display: flex;
       flex-direction: row;
       overflow: hidden;
    }

  .sub_food_container{
        width: 400px;
        margin-left: 0;
        overflow: visible;
  }

  @keyframes  moveEating  {
    0%{
        transform: translateX(0);
    }
    100% {
        transform: translateX(-49px);
    }
  }
  
  .berry{
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    background-color: white;
    animation: moveEating 3s infinite;
    animation-timing-function: linear;
  }

  .food{
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgb(163, 96, 14);
    border: 3px solid rgb(163, 96, 14);
    margin-left: 10px;
    border-radius: 50%;
    animation: moveEating 3s infinite;
    animation-timing-function: linear;

  }

  @media all and (max-width: 750px){
    .Packman1,
    .Packman2{
        height: 80px;
        width: 80px;
    } 

    .berry{
        height: 40px;
        width: 40px;
    }
    .Packman_conteiner{
        margin-top: auto;
        margin-bottom: auto;
        height: auto;
    }

    .food{
        height: 40px;
        width: 40px;
    }

    .food_container{
        left: 30px;
    }
    }