.maincontainer{
  display: flex;
  position: absolute;
  margin-bottom: auto;
  margin-right: auto;
  background-color: rgb(50, 50, 50);
  width: 100%;
  height: 100vh;
  animation: reduce 1s;
  animation-fill-mode: forwards;
  animation-delay: 5.5s;
}

@keyframes  reduce{
  0%{
      opacity: 1;
      
  }
  
  100% {
      opacity: 0;
  }
}

.logocontainer{
    width:  80%;
    z-index: 10;
    position: relative;
    display: flex;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.logo_border{
  display: flex;
  position: absolute;
  top:146px;
  left:0;
  width:  100%;
  overflow: hidden;
  animation: dissapear 0.1s;
  animation-fill-mode: forwards;
  animation-delay: 5s;

}

.imagen__color__logo{
    position: relative;
    background-color: rgb(50, 50, 50);
    width: 100%;
    height: 100%;
    top:125px;
    overflow: hidden;
    right: 0;
    z-index: 10;
    animation: width_logo 5s;
    animation-fill-mode: forwards;
}

@keyframes  width_logo {
  0%{
      width: 100%;
      
  }
  100% {
      width: 0;
  }
}
.logo_original{
  width: 80%;
  top:144px;
  position: fixed;
  z-index: 10;
}

.logocontainer_movil{
  display: none;
}

