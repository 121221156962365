@import url('./../..//const/colors.css');

.body__conteiner{
    min-height: 100vh;
    overflow: hidden;
    opacity: 1;

    /* animation: apear 1s;
    animation-fill-mode: forwards;
    animation-delay: 5.5s; */
}

@keyframes  apear {
    0%{
        opacity: 0;
        
    }
    
    100% {
        opacity: 1;
        height: auto;
    }
  }


.body{
    padding: 0 ;
    /* background-color: rgb(0, 255, 166); */
    width: 100%;
    min-height: 500px;

    
}

.animation__starting__conteiner{
    position: relative;
}
.main__container{
    position: relative;
    margin-top: 0 !important;
}

.header__conteiner{
    z-index: 100;
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    padding: 0 80px;
}

.logo__conteiner,
.logo{
    width: 200px;
    height: auto;
    position: relative;
    display: flex;
}
.maincontainer{
    width: 100%;
    min-height: 500px;
    margin-top: 0;
    overflow: hidden;
}
.rotate__body{
    
  animation: rotate 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}


@keyframes rotate {
    0% {transform:  rotateY(0deg);}
    1%{height: 100vh; overflow: hidden;}
    100% {transform: perspective(10px) rotateX(1deg); height: 100vh; overflow: hidden;}
}

.rotate__body__reverse {
    animation: rotate-reverse 2s linear forwards;
    
}

@keyframes rotate-reverse {
    0% { transform: perspective(10px) rotateX(1deg); height: 100vh; overflow: hidden;}
    99%{height: 100vh; overflow: hidden;}
    100% { transform: rotateY(0deg); height: auto;}
}


.nav_bar_disable{
    display: none;
    overflow: hidden; 

}


.nav_bar_active{
    display: flex;
    height: auto;
}

.btn__text{
    display: none;
    font-size: 25px;
    position: relative;
    margin-top:17px;
    transform: translateX(57px);
    font-family: Forte;
    letter-spacing: 2px;
    color: white;
    /* background-color: black; */
    /* width: 40px; */
    height: 40px;

}

.btn__icon{
    font-size: 100px !important;
    display: flex;
    color: white;
}
.btn{
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center !important;
    justify-content: center;
    background: linear-gradient(var(--pink), var(--blue));
    border: none;
    margin-left: auto;
    overflow: hidden;
}
.btn:hover .btn__icon{
    display: none;
}

.btn:hover .btn__text{
    display: flex;
    animation: text_move 4s infinite;
    animation-timing-function: linear;

}

@keyframes text_move{
    0% { transform: translateX(57px);}
    100% { transform: translateX(-48px);}
}