.main__container{
    width: 50%;
    min-width: 700px;
    height: auto;
    min-height: 600px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    padding: 10px;
    position: relative;
    margin-bottom: 50px;
    margin-top: 50px;
    /* background-color: aqua; */
    align-items: center;
    padding: 40px;
    border: 3px solid var(--blue);
    
}
.background {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    min-width: 700px;
    height: 100%;
    min-height: 600px;
    background-image: url('./../../imagenes/_46d88c9b-31f8-4f9d-b5b5-5c5952ae44c4.jpg'); 
    background-size:calc(min-width,50%) ; 
    
    background-position: center ;
    background-repeat: no-repeat;
    z-index: -1;
    background-attachment: fixed; /* Fijar la imagen de fondo */
  }

  .word__conteiner{
    display: flex;
    flex-direction: column;
    background-color: rgba(169, 219, 248, 0.447);
    height: 340px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    width: 330px;
  }

  .line{
    width: 0;
    transition: width 2s;
  }
  .word{
    font-family: Bodoni MT ;
    color: var(--pink);
    font-size: 40px;
    height: 40px;
    margin-bottom: 15px;
    /* border: 2px solid red; */
    position: relative;
    
    display: flex;
    /* width: 100%; */
  }

  .impares__letters,
  .par__letters{
    position: relative;
    display: flex;
    /* background-color: aqua; */
    /* padding: 3px; */
    margin-top: -5px;
    transition: 1s;
    
  }

  .word:hover .impares__letters {
    margin-top: -5px;
    cursor: pointer;
    transform: skewX(12deg);
    color: rgb(35, 61, 71);
  }

  .word:hover .par__letters{
    margin-top: 5px;
    margin-left: 10px;
    cursor: pointer;
    transform: skewX(-12deg);
    color: rgb(35, 61, 71);

  }

  .word:hover {
    cursor: pointer;
    justify-content: center;
    font-size: 30px;
    
  }

  .word:hover .line{
    z-index: 10;
    position: absolute;
    opacity: 1;
    border-bottom: 3px solid var(--pink);
    display: flex;
    width: 100%;
    top: 5px;
    color: var(--pink);
  }

  @media all and (max-width: 750px){
    .main__container{
      min-width:100%;
      
  }
  }