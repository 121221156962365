/* footer.module.css */

.footer {
    background-color: #282828;
    
    color: #fff;
    padding: 20px 0;
    text-align: center;
    font-family: 'Arial', sans-serif;
    background-image: url('./../../imagenes/Untitled.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .footer__container {
    background-color: #2828288c;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .footer__container > div {
    flex: 1 1 200px;
    margin: 10px;
  }
  
  h4 {
    margin-bottom: 10px;
  }

  .icon__container{
    display: flex;
    align-items: center;
    justify-content: center;
}
  
  .icon__container a  {
    margin: 0 5px;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    border: 3px solid #fff;
    height: 40px;
    width: 40px;
    display: flex !important;
    background-color: #282828;
    border-radius: 50%;
    justify-content: center !important;
    align-items: center !important;
    transition: 0.75s;
    
  }

  
  
  .icon__container a:hover {
    color: var(--green);
    border: 3px solid var(--green);
    background-color: #fff;
    font-size: 27px;
    cursor: pointer;
  }
  
  .nav__links {
    list-style-type: none;
    padding: 0;
  }
  
  .nav__links li {
    margin: 5px 0;
  }
  
  .nav__button {
    background-color: #282828 !important;
    /* border: none; */
    color: var(--green) !important;
    cursor: pointer;
    border: 2px solid var(--green) !important;
    transition: 0.75s;
    margin-bottom: 10px;
  }
  
  .nav__button:hover {
    color:#fff !important;
    background-color:var(--green) !important;
    border: 2px solid #fff !important; 
    font-size: 20px;
  }
  
  .contact__info p {
    margin: 5px 0;
    font-size: 17px;
  }
  
  .contact__button {

    background-color:  var(--green) !important;
    border: none !important;
    color:  #fff !important;
    cursor: pointer;
    transition: 0.75s;
    margin-bottom: 10px;

  }
  
  .contact__button:hover {
    background-color: transparent;
    border: 2px solid #fff !important;
    color: #fff !important;
    font-size: 18px;
  }
  
  .footer__bottom {
    margin-top: 20px;
    border-top: 1px solid #444 !important;
    padding-top: 10px;
  }
  

  .logo__conteiner,
.logo{
    width: 400px;
    height: auto;
    position: relative;
    display: flex;
    margin-top: 20px;

}


.social__media_movil{
  display: none;
}
@media all and (max-width: 750px) {
  .footer__container {
      flex-direction: column;
      align-items: center;
  }

  .footer__container > div {
      flex: 1 1 100%;
      margin: 10px 0;
  }

  .icon__container {
      justify-content: center;
  }

  .logo__conteiner,
  .logo {
      width: 300px;
      margin-top: 0;
      margin-bottom: 10px;
  }

  .social__media_movil{
    background-color: #2828288c;
    display: flex;
    flex-direction: column;
    
  }

  .social__media{
    display: none;
  }

}