.main__conteiner{
    background-color: var(--pink);
    width: 40%;
    min-width: 400px;
    height: 0;
    display: flex;
    flex-direction: column;
    margin-left: 30%;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    
    animation: more_height 1.5s; /* Reducción de tiempo */
    animation-timing-function: ease-in-out;
    animation-delay: 0.2s; /* Ajustado para que inicie más rápido */
    animation-fill-mode: forwards;
}

@keyframes more_height {
  0%   { height: 0;}
  100% { height: auto; padding-bottom: 10px;}
}

.text__conteiner{
    background-color: rgb(0, 255, 174);
    margin-top: 0px;
    width: 380px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 120px;
    background-color: transparent !important;
    border: none !important;
}

.text,
.text2{
    color: var(--blue);
    font-family: Bodoni MT ;
    font-size: 50px;
    width: 380px;
    text-align: start;
}

.text2{
    position: absolute;
    z-index: 1;
    height: 50px;
    bottom: 26px;
    transition: 1s; /* Reducción de tiempo */
}

.text2__conteiner{
    position: relative;
    z-index: 1;
    height: 50px;
    width: 380px;
    overflow: hidden;
    margin-top: 0;
}

.text{
    transition: 1s; /* Reducción de tiempo */
    height: 43px;
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
}

.text__conteiner:hover .text{
    transform: translateX(-10px) skewX(12deg);
    cursor: pointer;
}

.text__conteiner:hover .text2{
    transform: translateX(10px) skewX(12deg);
    cursor: pointer;
}

.line{
    color: transparent;
    background-color: transparent !important;
    height: 2px;
    width: 200px;
    margin-top: 0;
    margin-bottom: 0;
    display: none;
    margin-left: auto;
    margin-right: auto;
    transition: 1s; /* Reducción de tiempo */
    opacity: 1;
}

.text__conteiner:hover .line{
    display: flex;
}

.text__conteiner__animation__H{
    transform: translateX(-120%);
    animation: btn_move 1.5s; /* Reducción de tiempo */
    animation-timing-function: ease-in-out;
    animation-delay: 0.5s; /* Ajustado para que inicie más rápido */
    animation-fill-mode: forwards;
}

.text__conteiner__animation__P{
    transform: translateX(-120%);
    animation: btn_move 1.5s; /* Reducción de tiempo */
    animation-timing-function: ease-in-out;
    animation-delay: 1s; /* Ajustado para que inicie más rápido */
    animation-fill-mode: forwards;
}

.text__conteiner__animation__A{
    transform: translateX(-120%);
    animation: btn_move 1.5s; /* Reducción de tiempo */
    animation-timing-function: ease-in-out;
    animation-delay: 1.5s; /* Ajustado para que inicie más rápido */
    animation-fill-mode: forwards;
}

@keyframes btn_move {
    0%   { transform: translateX(-120%)}
    100% { transform: translateX(0)}
}

.btn__social__media__container{
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.btn__social__media{
    border-radius: 50%;
    text-align: center ;
    width: 40px;
    height: 40px;
    color: var(--blue) !important;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    background-color: transparent;
    border: 2px solid var(--blue);
    transition: 0.5s; /* Reducción de tiempo */
    opacity: 0;

    animation: btn_appear 1.5s; /* Reducción de tiempo */
    animation-timing-function: ease-in-out;
    animation-delay: 2s; /* Ajustado para que inicie más rápido */
    animation-fill-mode: forwards;
}

@keyframes btn_appear {
    0%   { opacity: 0;}
    100% { opacity: 1;}
}

.btn__social__media:hover{
    cursor: pointer;
    background-color: var(--blue);
    color: white !important;
}

.btn__social__media:hover .icon{
    font-size: 22px;
}

.icon{
    margin: auto;
    font-size: 18px;
}

@media all and (max-width: 750px){
    .main__conteiner{
        min-width: 95%;
        margin-left: 2.5%;
        margin-top: 70px;
    }

    .text2{
        bottom: 29px;
    }
}
