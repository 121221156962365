.maincontainer{
    width: 100%;
    height: 90vh;
    min-height: 500px;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: flex-start ;
    align-items: center;
    padding: 60px;
    margin-bottom: 160px;
    /* background-color: aqua; */
    
}

.background {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    z-index: -1;
    background-size: cover; 
  }


.text{
    color: var(--blue);
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    font-size: 28px;
    width: 100%;
    min-width: 500px;
    text-align: start;
    display: flex;
    margin-left: 0;
    
   
}

.text__container{
    /* background-color: bisque; */
    width: 60%;
    min-width: 500px;
 
}

.title {
    font-family: Bodoni MT ;
    font-size: 46px;
    font-weight: bold;
    color: var(--blue);
    text-align: center;
  }
  
  .text {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #555;
    opacity: 1;
  }

  @media all and (max-width: 900px){
   
    .maincontainer{
        align-items: end;
        padding: 15px;
        height: 110vh;
    }

    .background {
        position: absolute; 
        top: 0;
        left: 0;
        width: 100%;
        height: 110vh;
        z-index: -1;
        background-size: cover; 
      }

      .text,.text__container{
        min-width: 100% !important;
        width: 100%;
      }
    
  }